<template lang="pug">
  header.header-faceless-flow
    .top
      .container.padding-0
        .row
          .col-md-12
            nav.navbar
              div.d-flex.align-items-center
                router-link.navbar-brand.logo(:to="{name: 'home', params: { collectionType: $route.params.collectionType }}" v-if="logoFont")
                  img(:src="logoFont.logo" :alt="resellerInfo.name")
              div.nav-title(:class="{ withLogo: (logoFont ? logoFont.logo : '') }") {{ headerTitle }}

              .abstract-forms(v-if="getAbstractFormsCollection.length > 1")
                label(
                  class="abstract-forms-item"
                  v-for="(item, key) in getAbstractFormsCollection"
                  :key="key"
                )
                  input(
                    type="radio"
                    name="abstract-form"
                    class="abstract-forms-input"
                    :value="`${item.toLowerCase()}`"
                    :checked="item.toLowerCase() === generalFormsCollectionName"
                    @change="changeCollection(item)"
                  )
                  span {{ $t(item.toLowerCase()) }}
              .order-info-cell(v-if="!isConfirmed")
                div(v-if="hasDeliveryTime")
                  span.order-info-text {{ deliveryTimeText }}
                  span.order-info-text {{ deliveryDaysCountFrom.number }}
                  span.order-info-text {{ deliveryDaysCountFrom.text.trim() === deliveryDaysCountTo.text.trim() ? '' : deliveryDaysCountFrom.text }} -
                  span.order-info-text {{ deliveryDaysCountTo.number }}
                  span.order-info-text {{ deliveryDaysCountTo.text }}
                //span.order-info-text {{ $t("price") }}
                template(v-if="totalMonthlyCost")
                  span.order-info-text-big {{ calculatedPrice }}
                  span.order-info-text {{ currencySymbol }}/{{ $t('mon') }} <br>
                  span(v-if="!getIsVatIncluded") &nbsp; {{ $t('exmoms') }}
</template>

<script>
import {mapState, mapGetters,} from 'vuex';
import {formatPrice} from "../../helpers/helpers";

export default {
  name: 'NewHeader',
  data: () => ({
    mainCampaign: {},
    isConfirmed: false
  }),
  computed: {
    ...mapGetters('financing', [
      'totalMonthlyCost'
    ]),
    ...mapGetters('product', [
      'getDeliveryDaysCount',
      'deliveryDaysCountFrom',
      'deliveryDaysCountTo',
      'getIsVatIncluded',
    ]),
    ...mapState('global', ['isHomePage',]),
    ...mapState('reseller', {
      resellerInfo: state => state.resellerInfo,
      logoFont: state => state.customStyle.logoFont || '',
      headerTitle: state => state.customStyle.topHeaders && !!state.customStyle.topHeaders.data.length ? state.customStyle.topHeaders.data[0].headline : '',
      campaigns: state => state.customStyle.campaigns && !!state.customStyle.campaigns.data.length ? state.customStyle.campaigns.data[0] : [],
      hasDeliveryTime: state => state.resellerInfo.settings?.deliveryTime,
      headerHeadlines: (state) =>
          state.customStyle.headerHeadlines &&
          !!state.customStyle.headerHeadlines.data.length
              ? state.customStyle.headerHeadlines.data[0]
              : [],
      deliveryTimeText: state => state.customStyle.listItem?.delivery_time_text || '',
      currencySymbol: state => state.resellerInfo?.market?.currency?.symbol || 'kr',
      market: state => state.resellerInfo?.market || {},
    }),
    ...mapGetters('reseller', ['getPartnerKey',]),
    ...mapGetters('filter', ['getAbstractFormsCollection',]),
    generalFormsCollectionName: {
      get() {
        return this.$store.state.global.generalFormsCollectionName
      },
      set(value) {
        this.$store.commit('global/setGeneralFormsCollectionName', value)
      }
    },
    standardPrice() {
      if (this.campaigns.price) {
        return this.campaigns.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }

      return ''
    },
    calculatedPrice() {
      return formatPrice(window.location.pathname.includes('/confirmed/')
          ? JSON.parse(localStorage.getItem('vehicleInfo')).price
          : this.totalMonthlyCost
      );
    },
  },
  watch: {
    '$route.params': function () {
      if (window.location.pathname.includes('confirmed')) {
        this.$set(this.$data, 'isConfirmed', true)
      } else {
        this.$set(this.$data, 'isConfirmed', false)
      }
    }
  }
}
</script>

<style lang="sass">
@import '@/style/components/Header.sass'
.header-faceless-flow
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1)
  position: sticky
  top: 0
  z-index: 999
  background: #000000

  .padding-0, .order-info-cell
    padding: 0

  .top
    img
      filter: invert(0)

  .nav-title-faceless
    font-style: normal
    font-size: 21px
    line-height: 100%
    text-align: center
    letter-spacing: -0.03em
    color: #FFFFFF
    text-transform: uppercase

  .nav-title-faceless::first-letter
    text-transform: uppercase

  .order-info-text-big
    font-size: 50px
    font-weight: bold
    letter-spacing: -0.03em

  .order-info-cell
    border-left: none !important

    .order-info-text
      font-size: 16px
      line-height: 0
</style>

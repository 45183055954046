/* eslint-disable no-dupe-keys */
/* eslint-disable no-extra-boolean-cast */
import {mapGetters, } from 'vuex';
import { parseCss, buildMediaQuery, hexToRgbA } from "../helpers/helpers";

export default {
  computed: {
    ...mapGetters('filter', ['getAbstractFormsCollection', ]),
  },
  methods: {
    setStyles(styles) {
      const oldStyle = document.getElementById('custom-style');

      if (oldStyle !== null) oldStyle.remove();

      styles.topHeaders.data = !!styles.topHeaders.data.length ? styles.topHeaders.data : [{}];
      styles.campaigns.data = !!styles.campaigns.data.length ? styles.campaigns.data : [{}];
      styles.includes.data = !!styles.includes.data.length ? styles.includes.data : [{}];
      styles.headerHeadlines.data = !!styles.headerHeadlines.data.length
        ? styles.headerHeadlines.data
        : [{}]

      const cssObject = {
        '@font-face': [
          {
            'font-family': 'CustomFont',
            src: `url(${styles.logoFont.font})`,
            'font-style': 'normal',
            'font-weight': 400,
          },
          {
            'font-family': 'CustomFontBold',
            src: `url(${styles.logoFont.font_bold})`,
            'font-style': 'normal',
            'font-weight': 700,
          },
          {
            'font-family': 'CustomFontLight',
            src: `url(${styles.logoFont.font_light})`,
            'font-style': 'normal',
            'font-weight': 100,
          },
        ],
        '.nav-title': {
          'color': `${styles.topHeaders.data[0].color}`,
          'text-align': `${styles.topHeaders.data[0].position}`,
          'margin': `${styles.topHeaders.data[0].position_margin}px`,
          'margin-top': `${styles.topHeaders.data[0].margin_top}px`,
          'font-size': `${styles.topHeaders.data[0].size}px`,
          'right': this.getAbstractFormsCollection.length > 1 ||
            styles.topHeaders.data[0].position === 'center' ? '' : 0,
          'justify-content': styles.topHeaders.data[0].position === 'center' ? 'center' :
            styles.topHeaders.data[0].position === 'right' ? 'flex-end' : 'flex-start',
        },
        '.customizable .main-campaign': {
          'right': `${styles.campaigns.data[0].position === 'right' ? '0' : styles.campaigns.data[0].position === 'left' ? 'auto' : 'calc(50% - 215px)'}`
        },
        '.customizable .main-campaign-headline': {
          'font-size': `${styles.campaigns.data[0].size}px`,
          'margin': `0 ${styles.campaigns.data[0].position_margin}px 0 ${styles.campaigns.data[0].position_margin}px`,
          'padding-top' : `${styles.campaigns.data[0].margin_top}px`,
          'text-align': `${styles.campaigns.data[0].position}`,
          'color': `${styles.campaigns.data[0].color}`,
        },
        '.customizable .main-campaign-title-name': {
          'font-size': `${styles.campaigns.data[0].intro_size}px`,
        },
        '.customizable .banner-content-wrapper': {
          'align-items': `${
            styles.headerHeadlines.data[0].position === 'center'
              ? 'center'
              : styles.headerHeadlines.data[0].position === 'right'
              ? 'flex-end'
              : 'flex-start'
          }`,
          'padding-top': `${styles.headerHeadlines.data[0].margin_top}px`,
          'padding-left': `${
            styles.headerHeadlines.data[0].position === 'left'
              ? styles.headerHeadlines.data[0].position_margin
              : 0
          }px`,
          'padding-right': `${
            styles.headerHeadlines.data[0].position === 'right'
              ? styles.headerHeadlines.data[0].position_margin
              : 0
          }px`,
        },
        '.customizable .banner-content': {
          'text-align': `${
            styles.headerHeadlines.data[0].position === 'center'
              ? 'center'
              : styles.headerHeadlines.data[0].position === 'right'
              ? 'right'
              : 'left'
          }`,
          'color': styles.headerHeadlines.data[0].color,
        },
        '.customizable .banner-heading': {
          'font-size': `${styles.headerHeadlines.data[0].headline_size}px`,
          'font-weight': styles.headerHeadlines.data[0].bold ? 700 : 300,
        },
        '.customizable .banner-subheading': {
          'font-size': `${styles.headerHeadlines.data[0].intro_size}px`,
        },
        '.customizable .filter': {
          'background': `${ styles.filterBlock.background_color }`,
        },
        '.customizable .pre-loader.filter-container.show:before': {
          'background': `${ styles.filterBlock.background_color }`,
        },
        '.customizable .filter .drop-down label': {
          'color': `${ styles.filterBlock.label_color }`,
        },
        '.customizable .filter .btn-collapse .text': {
          'color': `${ styles.filterBlock.collapse_btn_color }`,
        },
        '.customizable .filter .btn-collapse .bar, .customizable .filter .btn-collapse .bar:after, .customizable .filter .btn-collapse .bar:before': {
          'background': `${ styles.filterBlock.collapse_btn_color }`,
        },
        '.customizable .filter-headline': {
          'color': `${ styles.filterBlock.headline_color }`
        },
        '.customizable .slide-item-price': {
          'background': `${ styles.listItem.background_color }`,
          'color': `${ styles.listItem.text_color }`,
        },
        '.customizable .filter .btn-reset': {
          'background': `${ styles.filterBlock.reset_btn_color }`,
          'color': `${ styles.filterBlock.reset_btn_text_color }`,
        },
        '.customizable .filter .btn.btn-search': {
          'background': `${ styles.filterBlock.search_btn_color }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.customizable .filter .expand-filter-btn': {
          'fill': `${ styles.filterBlock.show_more_btn_color }`,
        },
        '.customizable .product-card-info': {
          'background': `${ styles.listItem.background_color }`,
          'color': `${ styles.listItem.text_color }`,
        },
        '.customizable .btn-hover.invert': {
          'background': `${ styles.listItem.choose_btn_color }`,
          'color': `${ styles.listItem.choose_btn_text_color }`,
        },
        '.customizable .btn-general': {
          'background': `${ styles.filterBlock.search_btn_color }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.customizable footer': {
          'background': `${ styles.footer.bg_color }`,
          'color': `${ styles.footer.text_color }`,
        },
        '.customizable footer a': {
          'color': `${ styles.footer.text_color }`,
        },
        '.customizable .footer-bottom': {
          'background': `${ styles.footer.copyright_bg_color }`,
          'color': `${ styles.footer.copyright_text_color }`,
        },
        '.customizable .order-status': {
          'background': `${ styles.steps.bg_color }`,
          'color': `${ styles.steps.text_color }`,
        },
        '.customizable .order-steps li': {
          'background': `${ styles.steps.bg_color }`,
          'color': `${ styles.steps.text_color }`,
        },
        '.customizable .order-steps-new li': {
          'color': `${ styles.steps.active_text_color }`,
        },
        '.customizable .order-steps-new li.active': {
          'background': `${ styles.steps.bg_color }`,
          'color': `${ styles.steps.text_color }`,
        },
        '.customizable .order-info-cell' : {
          'border-color': `${ styles.topHeaders.data[0].color }`,
        },
        '.customizable .order-info-cell:not(:first-child)': {
          'border-color': `${ styles.topHeaders.data[0].color }`,
        },
        '.customizable .order-steps li.active': {
          'background': `${ styles.steps.active_bg_color }`,
          'color': `${ styles.steps.active_text_color }`,
        },
        '.customizable .order-info-btn .btn': {
          'background': `${ styles.filterBlock.search_btn_color }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.customizable .check-list-circle': {
          'background': `${ styles.includes.data[0].circle_color }`,
          'color': `${ styles.includes.data[0].circle_text_color }`,
        },
        '.customizable .product-card-price-symbol': {
          'color': `${ styles.listItem.campaign_tag_text_color }`,
          'background': `${ styles.listItem.campaign_tag_color }`,
        },
        '.customizable .order-steps li:not(.active):hover': {
          'background': `${ styles.filterBlock.search_btn_color ? hexToRgbA(styles.filterBlock.search_btn_color, '0.6') : '#E5E4E5' }`
        },
        '.customizable .cost-block': {
          'background': `${ styles.accessoryPage.desc_block_bg_color }`,
          'color': `${ styles.accessoryPage.desc_block_text_color }`,
        },
        '.customizable .step-btn i': {
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.customizable a.step-btn.btn-hover': {
          'background': `${ styles.filterBlock.search_btn_color }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        'button.btn.btn-grey.btn-hover': {
          'background': `${ styles.filterBlock.search_btn_color  }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.cls-1': {
          'fill': `${ styles.filterBlock.search_btn_color }`,
        },
        '.cls-2': {
          'stroke': `${ styles.filterBlock.search_btn_text_color }`,
          'fill': `${ styles.filterBlock.search_btn_color }`,
        },
        '.cls-21': {
          'stroke': `${ styles.filterBlock.search_btn_color }`,
          'fill': `${ styles.filterBlock.search_btn_color }`,
        },
        '.cls-3': {
          'fill': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.customizable .custom-check-input:checked + .check': {
          'background': `${ styles.filterBlock.search_btn_color }`,
          'border-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.customizable .step-btn': {
          'background': `${ styles.filterBlock.search_btn_color }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.customizable .next-step-num': {
          'color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.customizable .radio-block-input:checked + .radio-block': {
          'border-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.customizable .contract-choicer-container .radio-block-input:checked + .radio-block': {
          'background': `${ styles.listItem.choose_btn_color }`,
          'color': `${styles.listItem.choose_btn_text_color}`
        },
        '.customizable .cost-tabs': {
          'background-color': `${ styles.filterBlock.search_btn_color }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.customizable .pick-up-location input:checked + .selected-bg': {
          'background': `${ styles.listItem.choose_btn_color }`,
        },
        '.customizable .pick-up-location:hover .selected-bg': {
          'background': `${ styles.listItem.choose_btn_color }`,
        },
        '.customizable .pick-up-location .pick-up-location-textbox.selected': {
          'color': `${styles.listItem.choose_btn_text_color}`
        },
        '.customizable .pick-up-location:hover .pick-up-location-textbox': {
          'color': `${ styles.listItem.choose_btn_text_color }`,
        },
        '.customizable .custom-scrollbar__knob': {
          'background': `${ styles.listItem.choose_btn_color }`,
        },
        body: {
          'font-family': `CustomFont, sans-serif`,
        },
        '.customizable a.navbar-brand.logo.router-link-exact-active.active': {
          'margin-top': `${styles.logoFont.logo_margin_top}px`,
          'margin-left': `${styles.logoFont.logo_margin_left}px`,
        },
        'header .bottom': {
          'height': `${styles.topHeaders.data && Object.keys(styles.topHeaders.data[0]).length ? styles.topHeaders.data[0].image_desktop_height : 275 }px`
        },
        'header .top': {
          'background': `${styles.topHeaders.data[0].bg_color || '#000000'} !important`,
        },
        '.customizable .costs-list-title': {
          'border-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.customizable .costs-list-content': {
          'border-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.customizable .costs-list-content .vue-slider-process': {
          'background-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.customizable .costs-list-content .vue-slider-dot-handle': {
          'background-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.customizable .vue-slider-dot-handle:after': {
          'border-top-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.customizable .costs-list.active .costs-list-title': {
          'background-color': `${ styles.filterBlock.search_btn_color }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
          'border-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.customizable .costs-list-title:hover': {
          'background-color': `${ styles.filterBlock.search_btn_color }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.infotooltip': {
          'background-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.infotooltip-content': {
          'background-color': `${ styles.filterBlock.search_btn_color }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.infotooltip-content:after': {
          'background-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '.customizable .abstract-forms-input:checked+span': {
          'background-color': `${ styles.filterBlock.search_btn_color }`,
          'color': `${ styles.filterBlock.search_btn_text_color }`,
        },
        '.customizable .vue-slider-dot-tooltip-top': {
          'color': `${ styles.filterBlock.search_btn_text_color }`,
          'background-color': `${ styles.filterBlock.search_btn_color } !important`,
        },
        '.customizable .vue-slider-dot-tooltip-inner': {
          'color': `${ styles.filterBlock.search_btn_text_color }`,
          'background-color': `${ styles.filterBlock.search_btn_color } !important`,
        },
        '.customizable .costs-container .costs-list-slider .vue-slider-rail .vue-slider-process': {
          'background-color': `${ styles.filterBlock.search_btn_color }`,
        },
        '@media': [
          buildMediaQuery(
            ['max-width: 1200px'],
            {
              '.customizable header .bottom': {
                'background-image': `url(${styles.topHeaders.data[0].image_desktop})`,
                'height': `${styles.topHeaders.data[0].image_desktop ? `${styles.topHeaders.data[0].image_desktop_height}px` : '0px'}`,
              },
              '.customizable .banner-heading': {
                'font-size': `${Math.floor(styles.headerHeadlines.data[0].headline_size * 0.9)}px`,
              },
              '.customizable .banner-subheading': {
                'font-size': `${Math.floor(styles.headerHeadlines.data[0].intro_size * 0.9)}px`,
              },
              '.customizable .banner-content-wrapper': {
                'padding-top': `${Math.floor(styles.headerHeadlines.data[0].margin_top * 0.8)}px`,
                'padding-left': `${
                    styles.headerHeadlines.data[0].position === 'left'
                        ? Math.floor(styles.headerHeadlines.data[0].position_margin * 0.8)
                        : 0
                }px`,
                'padding-right': `${
                    styles.headerHeadlines.data[0].position === 'right'
                        ? Math.floor(styles.headerHeadlines.data[0].position_margin * 0.8)
                        : 0
                }px`,
              }
            }
          ),
          buildMediaQuery(
            ['max-width: 981px'],
            {
              '.customizable header .bottom': {
                'background-image': `url(${styles.topHeaders.data[0].image_mobile})`,
                'height': `${styles.topHeaders.data[0].image_mobile ? `${styles.topHeaders.data[0].image_mobile_height}px` : '0px'}`,
              },
              '.customizable .banner-heading': {
                'font-size': `${Math.floor(styles.headerHeadlines.data[0].headline_size * 0.8)}px`,
                'margin-bottom': '10px',
              },
              '.customizable .banner-subheading': {
                'font-size': `${Math.floor(styles.headerHeadlines.data[0].intro_size * 0.6)}px`,
              },
              '.customizable .banner-content-wrapper': {
                'padding-top': `${Math.floor(styles.headerHeadlines.data[0].margin_top * 0.6)}px`,
                'padding-left': `${
                    styles.headerHeadlines.data[0].position === 'left'
                        ? Math.floor(styles.headerHeadlines.data[0].position_margin * 0.6)
                        : 0
                }px`,
                'padding-right': `${
                    styles.headerHeadlines.data[0].position === 'right'
                        ? Math.floor(styles.headerHeadlines.data[0].position_margin * 0.6)
                        : 0
                }px`,
              }
            }
          ),
          buildMediaQuery(
            ['max-width: 667px'],
            {
              '.customizable header .bottom': {
                'background-image': `url(${styles.topHeaders.data[0].image_mobile})`,
                'height': `${styles.topHeaders.data[0].image_mobile ? '' : '0px'}`
              },
              '.customizable .banner-heading': {
                'font-size': `${Math.floor(styles.headerHeadlines.data[0].headline_size * 0.7)}px`,
                'margin-bottom': '8px',
              },
              '.customizable .banner-subheading': {
                'font-size': `${Math.floor(styles.headerHeadlines.data[0].intro_size * 0.5)}px`,
                'margin-bottom': '15px',
              },
              '.customizable .banner-content-wrapper': {
                'padding-top': `${Math.floor(styles.headerHeadlines.data[0].margin_top * 0.4)}px`,
                'padding-left': '20px',
                'padding-right': '20px',
                'text-align': 'center',
                'align-items': 'center'
              },
              'header .bottom .description': {
                display: 'none',
              }
            }
          ),
          buildMediaQuery(
            ['min-width: 1201px'],
            {
              '.customizable header .bottom': {
                'background-image': `url(${styles.topHeaders.data[0].image_desktop})`,
                'height': `${styles.topHeaders.data[0].image_desktop ? `${styles.topHeaders.data[0].image_lg_height}px` : '0px'}`
              }
            }
          ),
        ],
      };

      const css = parseCss(cssObject),
        head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('style');

        style.setAttribute('id', 'custom-style');

      head.appendChild(style);

      document.body.classList.add('customizable');

      style.type = 'text/css';

      if (style.styleSheet) {
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }

      // insert favicon

      if(styles.logoFont.favicon) {
        document.getElementById('favicon').setAttribute('href', styles.logoFont.favicon);
      }
    }
  },
  watch: {
    customStyle: function (styles) {
      this.setStyles(styles);
    }
  },
}
